<template>
  <div id="e-courses">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <template v-if="role === 'student'">
        <b-table :data="formattedECourses" hoverable>
          <b-table-column field="#" label="#" width="40" numeric v-slot="props">
            {{ formattedECourses.indexOf(props.row) + 1 }}
          </b-table-column>

          <b-table-column label="Subject" v-slot="props">
            {{ props.row.subject }}
          </b-table-column>

          <b-table-column label="Topic" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column label="Type" v-slot="props">
            {{ props.row.courseType }}
          </b-table-column>

          <b-table-column label="Duration" v-slot="props">
            {{ props.row.duration }}
          </b-table-column>

          <b-table-column label="Questions" v-slot="props">
            {{ props.row.numberOfQuestions }}
          </b-table-column>

          <b-table-column label="Actions" width="160" v-slot="props">
            <a
              class="is-small has-text-info"
              @click="initializeSolutionInfo(props.row)"
              style="font-size: 1.1em; margin-right: 16px"
            >
              <fa-icon icon="info-circle" size="lg" />
            </a>
            <a
              class="is-small has-text-success"
              @click="initializeSolution(props.row)"
              style="font-size: 1.1em"
            >
              <fa-icon icon="check-circle" size="lg" />
            </a>
          </b-table-column>

          <!-- <template slot="detail" slot-scope="props"></template> -->
        </b-table>
      </template>
      <template v-else>
        <b-table :data="eCourses" hoverable>
          <b-table-column field="#" label="#" width="40" numeric v-slot="props">
            {{ eCourses.indexOf(props.row) + 1 }}
          </b-table-column>

          <b-table-column label="School Class" v-slot="props">
            {{ props.row.schoolClass.name }}
          </b-table-column>

          <b-table-column label="Subject" v-slot="props">
            {{ props.row.subject.name }}
          </b-table-column>

          <b-table-column label="Topics" v-slot="props">
            {{ props.row.eCourseTopics.length }}
          </b-table-column>

          <b-table-column label="Actions" width="160" v-slot="props">
            <action-buttons
              @initialize-info="initializeInfo(props.row)"
              @initialize-update="initializeUpdate(props.row)"
              @initialize-remove="initializeRemove(props.row)"
            />
          </b-table-column>
        </b-table>
      </template>
    </template>
    <!-- Delete -->
    <app-modal context="remove" @remove="remove" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      user: null,
      role: null,
      schoolId: null,
      pageTitle: 'E Courses',
      eCourses: [
        {
          schoolClass: {
            name: '',
          },
          subject: {
            name: '',
          },
          eCourseTopics: [],
        },
      ],
      formattedECourses: [],
    }
  },
  watch: {
    eCourses(data) {
      this.formattedECourses = []
      if (this.role === 'student') {
        this.formattedECourses = data
          .flatMap((item) => {
            return item.eCourseTopics.flatMap((topic) => {
              return topic.eCourseWorks.map((work) => {
                if (work.published) {
                  return {
                    id: item.id,
                    topicId: topic.id,
                    name: topic.name,
                    subject: topic.eCourse.subject.name,
                    workId: work.id,
                    courseType: work.courseType,
                    duration: work.duration,
                    numberOfQuestions: work.numberOfQuestions,
                  }
                }
              })
            })
          })
          .filter((item) => item !== undefined)
      }
    },
  },
  methods: {
    initializeInfo(e_course) {
      this.$router.push(`/school/${this.schoolId}/e_course_info/${e_course.id}`)
    },
    initializeUpdate(e_course) {
      this.$router.push(`/school/${this.schoolId}/e_course/${e_course.id}`)
    },
    initializeRemove(e_course) {
      this.$store.commit('setAppModalActive', true)
      this.id = parseInt(e_course.id)
    },
    initializeSolution(e_course) {
      this.$router.push(
        `/school/${this.schoolId}/student/${this.user.context.student_id}/e_course_solution/${e_course.workId}`
      )
    },
    initializeSolutionInfo(e_course) {
      this.$router.push(
        `/school/${this.schoolId}/student/${this.user.context.student_id}/e_course_solution_info/${e_course.workId}`
      )
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteECourse($id: Int!) {
              deleteECourse(input: { id: $id }) {
                eCourse {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteECourse.errors,
            'Successfully deleted.'
          ).then(() => {
            this.$store.commit('setAppModalActive', false)
            this.$apollo.queries.eCourses.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.user = user
      this.role = user.role
      this.schoolId = user.school_id

      if (
        user.role === 'host' ||
        this.user.role === 'admin' ||
        this.user.role === 'educator'
      ) {
        this.$apollo.addSmartQuery('eCourses', {
          query: gql`
            query ECoursesQuery($userId: Int!) {
              eCourses(userId: $userId) {
                id
                schoolClass {
                  name
                }
                subject {
                  name
                }
                eCourseTopics {
                  id
                }
              }
            }
          `,
          variables: {
            userId: this.user.id,
          },
        })

        this.$store.commit('setSubMenus', [
          {
            name: 'New',
            route: `/school/${this.schoolId}/e_course/new`,
          },
        ])
      } else if (this.user.role === 'student') {
        this.$apollo.addSmartQuery('eCourses', {
          query: gql`
            query StudentECoursesQuery($userId: Int!) {
              eCourses(userId: $userId) {
                id
                eCourseTopics {
                  id
                  name
                  eCourse {
                    id
                    subject {
                      name
                    }
                  }
                  eCourseWorks {
                    id
                    courseType
                    duration
                    numberOfQuestions
                    published
                  }
                }
              }
            }
          `,
          variables: {
            userId: this.user.id,
          },
        })

        this.$store.commit('setSubMenus', [])
      }

      this.$apollo.queries.eCourses.refetch()
    })
  },
}
</script>
